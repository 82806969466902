body {
  padding: 0;
  margin: 0 8px 0 0;
  background-color: #f5f5f5;
}

.App {
  width: 100%;
  color: rgb(60, 60, 60);
}
.subtitle {
  position: fixed;
  top: 330px;
  left: -60px;
  font-family: Arial, Helvetica, sans-serif;
  rotate: 90deg;
  font-size: 40px;
  letter-spacing: 2px;
}
.wrapper-part {
  margin: 100px 0 0 240px;
  /* border: 1px solid gray; */
}
.wrapper-title {
  font-size: 52px;
  font-weight: bold;
}
.title-underline {
  width: 100%;
  border-bottom: 2px solid gray;
  margin-top: -10px;
}
.wrapper-txt1 {
  margin-top: 80px;
}
.wrapper-txt {
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
}
.wrapper-txt2 {
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  margin-left: 8px;
}
.subtitle-2 {
  font-weight: bold;
  font-size: 36px;
}
.title-underline2 {
  width: 100%;
  border-bottom: 2px solid gray;
  margin-top: 8px;
}
.faq-part {
  margin-left: 240px;
  font-weight: bold;
}
.palel-title1 {
  font-weight: bold;
}
.form-part {
  margin: 0 0 160px 240px;
}
.policy-part {
  margin: 0 0 160px 240px;
}
.footer-part {
  position: relative;
}
.footer-logo {
  position: absolute;
  margin: 0 auto 0 100px;
  width: 120px;
  height: 100px;
  background-image: url("./image/lyonesse_pictures_logo_kuro.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.footer-link-a a {
  text-decoration: none;
}
.footer-link {
  margin: 0 0 0 auto;
  color: #fff;
  background-color: rgb(33, 33, 33);
  font-family: "Krungthep";
  width: 200px;
  padding: 8px;
  cursor: pointer;
}
.footer-link:hover {
  opacity: 0.7;
}
.copy-light {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
  margin-bottom: 16px;
}

.form-txt {
  margin-left: 500px;
  font-size: 13px;
}
.input-label {
  font-weight: bold;
  line-height: 40px;
  font-size: 15px;
}
.text-input {
  width: 600px;
  height: 34px;
  border: 2px solid rgb(85, 85, 85);
  padding-left: 8px;
}
.text-textarea {
  width: 600px;
  height: 100px;
  border: 2px solid rgb(85, 85, 85);
  padding-left: 8px;
}

.url-txt {
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
}
.url-txt-2 {
  margin-bottom: -14px;
}
.form-txt2 {
  font-size: 13px;
  font-weight: bold;
}
.form-btn {
  width: 610px;
  height: 45px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: rgb(49, 49, 49);
  border: none;
  letter-spacing: 4px;
  font-size: 17px;
  cursor: pointer;
}
.form-btn:hover {
  color: rgb(49, 49, 49);
  background-color: rgb(200, 200, 200);
}
.alert-success {
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  /* body {
    margin: 0;
  } */
  .subtitle {
    display: none;
  }
  .wrapper-part {
    margin: 80px 0 0 40px;
    /* border: 1px solid gray; */
    width: 90%;
  }
  .wrapper-title {
    font-size: 30px;
    font-weight: bold;
  }
  .title-underline {
    width: 100%;
    /* border-bottom: 2px solid gray; */
    margin-top: -4px;
  }
  .title-underline2 {
    margin-top: -2px;
  }
  .wrapper-txt1 {
    margin-top: 40px;
  }
  .wrapper-txt {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }
  .wrapper-txt2 {
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
  }
  .wrapper-txt2 {
    font-weight: bold;
    font-size: 15px;
    line-height: 26px;
    margin-left: 8px;
  }
  .subtitle-2 {
    font-size: 28px;
  }
  .text-input {
    width: 280px;
    height: 34px;
    border: 2px solid rgb(85, 85, 85);
    padding-left: 8px;
  }
  .form-btn {
    width: 290px;
    height: 45px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background-color: rgb(49, 49, 49);
    border: none;
    letter-spacing: 4px;
    font-size: 17px;
    cursor: pointer;
  }
  .text-textarea {
    width: 280px;
    height: 100px;
    border: 2px solid rgb(85, 85, 85);
    padding-left: 8px;
  }
  .url-txt {
    font-size: 11px;
    line-height: 20px;
  }
  .url-txt-2 {
    margin-bottom: -14px;
  }
  .form-txt2 {
    font-size: 11px;
  }
  .faq-part {
    margin-left: 40px;
  }
  .form-part {
    margin: 0 0 60px 40px;
  }
  .policy-part {
    margin: 0 0 80px 40px;
  }
  .form-txt {
    margin-left: 0px;
    font-size: 13px;
  }
  .subtitle-2-sm {
    font-size: 24px;
  }
  .footer-link {
    width: 160px;
    padding: 6px;
    font-size: 14px;
  }
  .footer-logo {
    margin: 0 auto 0 20px;
    width: 80px;
    height: 60px;
  }
}
